/* Live */
export const unquieID =
  localStorage.getItem("company_app_id") === null ||
  localStorage.getItem("company_app_id") === undefined
    ? ""
    : localStorage.getItem("company_app_id");
//export const apiUrl = "https://walletapi.goepicware.com/api/";
//export const apiUrl = "https://walletapi.goepicpay.com/api/";
export const apiUrl =  process.env.REACT_APP_API_URL;

//export const baseUrl = "http://localhost:3001/";
//export const baseUrl = "https://merchant.goepicware.com/";
export const baseUrl = process.env.REACT_APP_BASE_URL;;
//export const baseUrl = "https://merchant.goepicpay.com/";
