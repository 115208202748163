export const GET_CUSTOMER_DETAILS = "getCustomerDetails";
export const SET_CUSTOMER_DETAILS = "setCustomerDetails";
export const GET_MISSIONS_LIST = "getMissionsList";
export const SET_MISSIONS_LIST = "setMissionsList";
export const GET_TOPUPPLAN_LIST = "getTopupPlanList";
export const SET_TOPUPPLAN_LIST = "setTopupPlanList";
export const GET_REWARDSETTING_LIST = "getRewardSettingList";
export const SET_REWARDSETTING_LIST = "setRewardSettingList";
export const GET_STATICBLOCKS_LIST = "getStaticblocksList";
export const SET_STATICBLOCKS_LIST = "setStaticblocksList";
export const GET_PRODUCT_LIST = "getProductList";
export const SET_PRODUCT_LIST = "setProducttList";

export const GET_GLOBAL_SETTINGS = "getGlobalSettings";
export const SET_GLOBAL_SETTINGS = "setGlobalSettings";
export const GET_STORE_LIST = "getStoreList";
export const SET_STORE_LIST = "setStoretList";
export const GET_STORE_DETAILS = "getStoreDetails";
export const SET_STORE_DETAILS = "setStoretDetails";
export const GET_CATEGORY_LIST = "getCategoryList";
export const SET_CATEGORY_LIST = "setCategorytList";
export const GET_PRODUCT_DETAIL = "getProductDetail";
export const SET_PRODUCT_DETAIL = "setProductDetail";

export const GET_LISTDATA = "getListData";
export const SET_LISTDATA = "setListData";
export const GET_DETAILDATA = "getDetailData";
export const SET_DETAILDATA = "setDetailData";
export const GET_FORMPOST = "getFormPost";
export const SET_FORMPOST = "setFormPost";
